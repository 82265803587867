import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This guide is under active development and likely contains many broken links`}</em></p>
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`The free and open source manual for cyber security.`}</p>
    <p>{`Our intention is to compile tactics from real-world pentesting and CTF write-ups to make an exhaustive resource for expanding your cyber security knoweldge at any level. Additionally, it can and should be used as a spoiler-free reference during CTFs and practical cyber security certification exams.`}</p>
    <h2 {...{
      "id": "pentesting-methodology",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pentesting-methodology",
        "aria-label": "pentesting methodology permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pentesting Methodology`}</h2>
    <p>{`While it makes sense for most of the methodology to occur in the order listed, some steps may be optional or occur before others.`}</p>
    <ul>
      <li parentName="ul">{`🗃 Organization- Setting yourself up for success`}</li>
      <li parentName="ul">{`🔭 Recon - Perform active and passive scans`}</li>
      <li parentName="ul">{`🔓 Access - Get initial access on your target`}</li>
      <li parentName="ul">{`🎖 PrivEsc- Upgrade privileges on your target`}</li>
      <li parentName="ul">{`📦 Infil Exfil - Upload tools or download important information`}</li>
      <li parentName="ul">{`🧹 Cleanup - Cover your tracks`}</li>
    </ul>
    <h2 {...{
      "id": "reading-this-guide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reading-this-guide",
        "aria-label": "reading this guide permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reading this Guide`}</h2>
    <p>{`Throughout this guide commands are presented in a format intended to quickly convey the correct syntax and meaning. If warranted, certain parts of the command are clarified in a list following the command.`}</p>
    <p>{`Here are some common shorthand used in this guide:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`localhost`}</inlineCode>{` is interchangeable with `}<inlineCode parentName="li">{`127.0.0.1`}</inlineCode>{` (see also `}<a parentName="li" {...{
          "href": "/glossary#host-machine"
        }}>{`host machine`}</a>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`target`}</inlineCode>{`, `}<inlineCode parentName="li">{`target2`}</inlineCode>{`, etc is the IP of a `}<a parentName="li" {...{
          "href": "/glossary#target-machine"
        }}>{`target machine`}</a>{` or machines`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`user`}</inlineCode>{` is a username for whatever you are trying to access`}</li>
    </ul>
    <h2 {...{
      "id": "contributing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contributing",
        "aria-label": "contributing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contributing`}</h2>
    <p>{`If there is any information that you think this guide is missing, please consider `}<a parentName="p" {...{
        "href": "https://github.com/Hack-Church/bible-hackchur-ch"
      }}>{`contributing to our project`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      